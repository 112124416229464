import React, { memo, useRef, useState } from 'react';
import Slider from 'react-slick';
import {
  HOME_ENDOW,
  HOME_BANNER_LOGO,
  HOME_NEWS,
  HOME_PRIZE,
} from './constants';
import { HomeWrapper } from './styled';
import { useSelector } from 'react-redux';
import {
  selectAward,
  selectHome,
  selectListSpl,
  selectNews,
} from './selectors';
import LinkInOutApp from '@components/LinkInOutApp';
import { removeScripts } from '@utils/helper';
import useIsPc from '@utils/hooks/useIsPc';
import { DialogPromotion } from '@components/DialogPromotion';

const HomePage = memo(() => {
  const isPc = useIsPc();
  const newRef = useRef(null);

  const home = useSelector(selectHome);
  const award = useSelector(selectAward);
  const news = useSelector(selectNews);
  const listSpl = useSelector(selectListSpl);

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [itemDialog, setItemDialog] = useState(null);
  const handleOpenDialog = (items, index) => {
    setIsDialogOpen(true);
    setItemDialog({ items, index });
  };

  return (
    <>
      <HomeWrapper id="home">
        <div className="homeBanner">
          <div className="container-fluid">
            <div className="--left">
              <h1 className="segTitle">
                <div className="--name">
                  <span>Sun</span>
                  <span>Paradise</span>
                  <span>Land</span>
                </div>
                {/* <div className="--title">
                Thiên đường <br />
                Du lịch & Nghỉ dưỡng <br />
                của Sun Group
              </div>
              <img src="/static/img/plant 1.png" alt="" className="--plan" /> */}
              </h1>
              <div
                className="--description"
                dangerouslySetInnerHTML={{
                  __html: removeScripts(
                    'Thương hiệu hệ sinh thái du lịch nghỉ dưỡng, vui chơi giải trí và bất động sản cao cấp của Tập đoàn Sun Group trên toàn quốc. ',
                  ),
                }}
              />

              <article
                dangerouslySetInnerHTML={{
                  __html: removeScripts(
                    'Hệ sinh thái Sun Paradise Land mang sứ mệnh phát triển bền vững những điểm đến du lịch Việt Nam - nơi thiên đường là có thật. Sun Paradise Land quy tụ những thương hiệu uy tín, mang đến trải nghiệm bất tận cho du khách cả ngày lẫn đêm.  ',
                  ),
                }}
              />
            </div>
            <div className="--right">
              <div className="--map">
                <div className="--img">
                  <img src="/static/img/map.png" alt="" />
                  <span>Click để khám phá từng vùng đất</span>
                </div>
                <div className="--marker">
                  <div className="--listMarker">
                    {listSpl?.data?.map(item => (
                      <div className="--item" key={item?.id}>
                        <LinkInOutApp
                          href={`/${item?.sitecode}`}
                          className="link"
                        >
                          <>
                            <img src="/static/img/Icon-tree.png" alt="" />
                            <div className="--name">{item?.name}</div>
                          </>
                        </LinkInOutApp>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              {home?.events.length > 0 && (
                <div className="--event">
                  <div className="--titleEvent">🔥 Sự kiện nổi bật </div>
                  <div className="--listEvent">
                    {home?.events.map((item, i) => (
                      <>
                        <LinkInOutApp
                          href={item?.info?.slug}
                          className="--item link"
                          key={i}
                        >
                          <>
                            <div className="--img">
                              <img
                                src={item?.info?.images?.[0]?.thumb1000}
                                alt=""
                              />
                            </div>
                            <div className="--txt">
                              <div className="--name">{item?.info?.name}</div>
                              <div className="--info">
                                <div className="--time">10 - 14/11/2024 /</div>
                                <div className="--cate">
                                  {item?.info?.category}
                                </div>
                              </div>
                            </div>
                          </>
                        </LinkInOutApp>
                      </>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div
            className={`--listLogo ${
              home?.partners?.length > 5 || !isPc ? '' : 'litle'
            }`}
          >
            {home?.partners?.length > 5 || !isPc ? (
              <Slider {...HOME_BANNER_LOGO}>
                {home?.partners?.map(item => (
                  <div className="--logo" key={item?.id}>
                    <img src={item?.imageResize?.thumb1000} alt="" />
                  </div>
                ))}
              </Slider>
            ) : (
              <>
                {home?.partners?.map(item => (
                  <div className="--logo" key={item?.id}>
                    <img src={item?.imageResize?.thumb1000} alt="" />
                  </div>
                ))}
              </>
            )}
          </div>
          <div className="--cloud">
            <img src="/static/img/cloud.png" alt="" />
          </div>
        </div>
        <div className="homeDesign">
          <div className="container-fluid">
            <div className="--background">
              <div className="--img">
                <img src="/static/img/bgdesign.png" alt="" />
              </div>
              <div className="--cloud">
                <img className="--cloud1" src="/static/img/may.png" alt="" />
                <img className="--cloud2" src="/static/img/may1.png" alt="" />
              </div>
              <div className="--house">
                <img className="--img1" src="/static/img/toanha.png" alt="" />
                <img className="--img2" src="/static/img/bg_1.png" alt="" />
                <img className="--img3" src="/static/img/may2.png" alt="" />
              </div>
              <img className="--house1" src="/static/img/swhalong.png" alt="" />
            </div>
            <div className="--txt">
              <img src="/static/img/plant 2.png" alt="" />
              <div className="segTitle">
                Hành trình của bạn <br /> Thiết kế bởi
              </div>
              <div className="subTitle">Sun Paradise Land</div>
              <article>
                Khám phá gợi ý trải nghiệm độc đáo dành riêng <br /> cho kỳ nghỉ
                của bạn - chỉ trong vài giây
              </article>
            </div>
          </div>
        </div>
        {home?.promotions?.length > 0 && (
          <div className="homeEndow">
            <div className="container-fluid">
              <div className="titleFrame">{home?.titlePromotions}</div>

              <div
                className={`--listEndow ${
                  home?.promotions?.length > (isPc ? 3 : 1) ? '' : 'litle'
                } `}
              >
                {home?.promotions?.length > (isPc ? 3 : 1) ? (
                  <Slider {...HOME_ENDOW}>
                    {home?.promotions?.map((item, i) => (
                      <div
                        className="--item"
                        key={i}
                        onClick={() => handleOpenDialog(home?.promotions, i)}
                      >
                        <img src={item?.image?.thumb1600} alt="" />
                      </div>
                    ))}
                  </Slider>
                ) : (
                  <>
                    {home?.promotions?.map((item, i) => (
                      <div
                        className="--item"
                        key={i}
                        onClick={() => handleOpenDialog(home?.promotions, i)}
                      >
                        <img src={item?.image?.thumb1600} alt="" />
                      </div>
                    ))}
                  </>
                )}
              </div>
            </div>
          </div>
        )}
        <div className="homeNews">
          <div className="container-fluid">
            <div className="--title">
              <div className="titleFrame">{home?.titleNews}</div>
              {/* {isPc && (
              <LinkInOutApp
                href="/tin-tuc"
                className="--viewDetail link textHover"
              >
                <>
                  Xem tất cả <i className="fa-regular fa-angle-right"></i>
                </>
              </LinkInOutApp>
            )} */}
            </div>
            <div className="slider">
              <div
                className={`--listNews ${
                  news?.data?.length > (isPc ? 3 : 1) ? '' : 'little'
                }`}
              >
                {news?.data?.length > (isPc ? 3 : 1) ? (
                  <Slider ref={newRef} {...HOME_NEWS}>
                    {news?.data?.map((item, i) => (
                      <LinkInOutApp
                        href={`/tin-tuc/${item?.slug || item?.id}`}
                        className="--item imgHover1 link"
                        key={i}
                      >
                        <>
                          {' '}
                          <div className="--img">
                            <img
                              src={item?.imageThumbnails?.thumb1000}
                              alt=""
                            />
                          </div>
                          <div className="--txt">
                            <div className="--cate">
                              {item?.newscategory?.name}
                            </div>
                            <div className="--name">{item?.title}</div>
                            <article
                              dangerouslySetInnerHTML={{
                                __html: removeScripts(item?.shortContent) || '',
                              }}
                            />
                            <div className="--time">
                              <i className="fa-regular fa-calendar"></i>{' '}
                              {item?.date}
                            </div>
                          </div>
                        </>
                      </LinkInOutApp>
                    ))}
                  </Slider>
                ) : (
                  <>
                    {news?.data?.map((item, i) => (
                      <LinkInOutApp
                        href={`/tin-tuc/${item?.slug}`}
                        className="--item imgHover1 link"
                        key={i}
                      >
                        <>
                          {' '}
                          <div className="--img">
                            <img
                              src={item?.imageThumbnails?.thumb1000}
                              alt=""
                            />
                          </div>
                          <div className="--txt">
                            <div className="--cate">
                              {item?.newscategory?.name}
                            </div>
                            <div className="--name">{item?.title}</div>
                            <article
                              dangerouslySetInnerHTML={{
                                __html: removeScripts(item?.shortContent) || '',
                              }}
                            />
                            <div className="--time">
                              <i className="fa-regular fa-calendar"></i>{' '}
                              {item?.date}
                            </div>
                          </div>
                        </>
                      </LinkInOutApp>
                    ))}
                  </>
                )}
              </div>
              {news?.data?.length > (isPc ? 3 : 1) && (
                <div className="arrow">
                  <div
                    className="--arrow prev"
                    onClick={() => newRef.current.slickPrev()}
                  >
                    <i className="fa-solid fa-chevron-left"></i>
                  </div>
                  <div
                    className="--arrow next "
                    onClick={() => newRef.current.slickNext()}
                  >
                    <i className="fa-solid fa-chevron-right"></i>
                  </div>
                </div>
              )}
            </div>
            {/* {!isPc && (
            <LinkInOutApp href="/tin-tuc" className="--viewDetail link">
              <>
                Xem tất cả <i className="fa-regular fa-angle-right"></i>
              </>
            </LinkInOutApp>
          )} */}
          </div>
        </div>
        <div className="homePrize">
          <div className="container-fluid">
            <div className="titleFrame">{award?.title}</div>
            <article
              dangerouslySetInnerHTML={{
                __html: removeScripts(award?.description) || '',
              }}
            />
            <div
              className={`--listPrize ${
                award?.items?.length > (isPc ? 5 : 1) ? '' : 'litle'
              }`}
            >
              {award?.items?.length > (isPc ? 5 : 1) ? (
                <Slider {...HOME_PRIZE}>
                  {award?.items.map((item, i) => (
                    <div className="--item" key={i}>
                      <div className="--img">
                        <img src={item?.imageResize?.thumb1000} alt="" />
                      </div>
                      <div className="--time">{item?.year}</div>
                      <div className="--name">{item?.name}</div>
                    </div>
                  ))}
                </Slider>
              ) : (
                <>
                  {award?.items.map((item, i) => (
                    <div className="--item" key={i}>
                      <div className="--img">
                        <img src={item?.imageResize?.thumb1000} alt="" />
                      </div>
                      <div className="--time">{item?.year}</div>
                      <div className="--name">{item?.name}</div>
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>
        </div>
      </HomeWrapper>
      {itemDialog && (
        <DialogPromotion
          isDialogOpen={isDialogOpen}
          setIsDialogOpen={setIsDialogOpen}
          itemDialog={itemDialog}
        />
      )}
    </>
  );
});
export default HomePage;
