import { Modal, Button } from 'antd';
import Slider from 'react-slick';
import { DIALOG_PROMOTION } from './constants';

export interface Props {
  isDialogOpen: boolean;
  setIsDialogOpen: (value: boolean) => void;
  itemDialog: any;
}

export const DialogPromotion = ({
  isDialogOpen,
  setIsDialogOpen,
  itemDialog,
}: Props) => {
  const handleOk = () => {
    setIsDialogOpen(false);
  };
  const handleCancel = () => {
    setIsDialogOpen(false);
  };

  if (!isDialogOpen) return null;

  return (
    <Modal
      className="dialogPromotion"
      onOk={handleOk}
      onCancel={handleCancel}
      open={isDialogOpen}
      footer={null}
      closeIcon={false}
      centered
    >
      <Button className="--close" onClick={() => setIsDialogOpen(false)}>
        <i className="fa-light fa-xmark" />
      </Button>
      <div onClick={() => setIsDialogOpen(false)} />
      <Slider {...DIALOG_PROMOTION} initialSlide={itemDialog?.index}>
        {itemDialog?.items?.map((item, i) => (
          <div className="--item" key={i}>
            <img src={item?.image?.thumb1600} alt="" />
          </div>
        ))}
      </Slider>
    </Modal>
  );
};
