import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '@type/index';
import { initialState } from './slice';

const selectDomain = (state: RootState) => state.hotel || initialState;

export const selectIsLoading = createSelector(
  [selectDomain],
  state => state.loading,
);

export const selectIsError = createSelector(
  [selectDomain],
  state => state.error,
);

export const selectIsSuccess = createSelector(
  [selectDomain],
  state => state.success,
);

export const selectType = createSelector(
  [selectDomain],
  state => state.type || undefined,
);

export const selectHotel = createSelector(
  [selectDomain],
  state => state.hotel || undefined,
);

export const selectListHotel = createSelector(
  [selectDomain],
  state => state.listHotel || undefined,
);
