/* eslint-disable no-nested-ternary */
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '@type/index';
import { initialState } from '../slice';

const selectDomain = (state: RootState) => state.app || initialState;

export const selectApp = createSelector(
  [selectDomain],
  dashboardState => dashboardState,
);

export const selectIsLoading = createSelector(
  [selectDomain],
  state => state.loading,
);

export const selectIsError = createSelector(
  [selectDomain],
  state => state.error,
);

export const selectIsSuccess = createSelector(
  [selectDomain],
  state => state.success,
);

export const selectHome = createSelector(
  [selectDomain],
  state => state.home || undefined,
);
export const selectAward = createSelector(
  [selectDomain],
  state => state.award || undefined,
);
export const selectNews = createSelector(
  [selectDomain],
  state => state.news || undefined,
);
export const selectTranslate = createSelector(
  [selectDomain],
  state => state.translate || undefined,
);
export const selectListSpl = createSelector(
  [selectDomain],
  state => state.listSpl || undefined,
);

export const selectFooter = createSelector(
  [selectDomain],
  state => state.footer || undefined,
);
