// import { BaseXHR } from '@utils/axios';
import { put, takeLatest, call } from 'redux-saga/effects';
import { BaseXHR } from '@utils/axios';
import { GET_POLICY } from './slice';

export function* getPolicy() {
  try {
    const { data } = yield call(
      BaseXHR.$get,
      `/api/page/detail/dieu-khoan-su-dung?sitecode='SunParadiseLandPhuQuoc'`,
      undefined,
      {},
    );

    yield put(GET_POLICY.success(data));
  } catch (err) {
    yield put(GET_POLICY.failure());
  }
}

const policySagas: any = [takeLatest(GET_POLICY.TRIGGER, getPolicy)];

export default policySagas;
