import { combineReducers } from 'redux';
import {
  reducer as appReducer,
  sliceKey as sliceKeyApp,
} from '@containers/Home/slice';
import { HYDRATE } from 'next-redux-wrapper';
import {
  reducer as detailParkReducer,
  sliceKey as sliceKeyDetailPark,
} from '@containers/DetailPark/slice';
import {
  reducer as activitiesReducer,
  sliceKey as sliceKeyActivities,
} from '@containers/Activities/slice';
import {
  reducer as hotelReducer,
  sliceKey as sliceKeyHotel,
} from '@containers/Hotel/slice';
import {
  reducer as detailActivitiesReducer,
  sliceKey as sliceKeyDetailActivities,
} from '@containers/DetailActivities/slice';
import {
  reducer as detailDestinationReducer,
  sliceKey as sliceKeyDetailDestination,
} from '@containers/DetailDestination/slice';
import {
  reducer as detailHotelReducer,
  sliceKey as sliceKeyDetailHotel,
} from '@containers/DetailHotel/slice';
import {
  reducer as destinationReducer,
  sliceKey as sliceKeyDestination,
} from '@containers/Destination/slice';
import {
  reducer as eatReducer,
  sliceKey as sliceKeyEat,
} from '@containers/Eat/slice';
import {
  reducer as playReducer,
  sliceKey as sliceKeyPlay,
} from '@containers/Play/slice';
import {
  reducer as shopingReducer,
  sliceKey as sliceKeyShoping,
} from '@containers/Shoping/slice';
import {
  reducer as detailNewsReducer,
  sliceKey as sliceKeyDetailNews,
} from '@containers/DetailNews/slice';
import {
  reducer as policyReducer,
  sliceKey as sliceKeyPolicy,
} from '@containers/Policy/slice';

const combinedReducer = combineReducers({
  [sliceKeyApp]: appReducer,
  [sliceKeyDetailPark]: detailParkReducer,
  [sliceKeyActivities]: activitiesReducer,
  [sliceKeyHotel]: hotelReducer,
  [sliceKeyDetailActivities]: detailActivitiesReducer,
  [sliceKeyDetailNews]: detailNewsReducer,
  [sliceKeyDetailDestination]: detailDestinationReducer,
  [sliceKeyDetailHotel]: detailHotelReducer,
  [sliceKeyDestination]: destinationReducer,
  [sliceKeyEat]: eatReducer,
  [sliceKeyPlay]: playReducer,
  [sliceKeyShoping]: shopingReducer,
  [sliceKeyPolicy]: policyReducer,
});

export const reducers = (state, action) => {
  if (action.type === HYDRATE) {
    let nextState = {
      ...state,
      ...action.payload,
    };

    if (state.app.notification)
      nextState = {
        ...nextState,
        app: {
          ...nextState.app,
          notification: state.app.notification,
        },
      };
    if (state.app.award)
      nextState = {
        ...nextState,
        app: {
          ...nextState.app,
          award: state.app.award,
        },
      };
    if (state.app.footer)
      nextState = {
        ...nextState,
        app: {
          ...nextState.app,
          footer: state.app.footer,
        },
      };
    if (state.app.listSpl)
      nextState = {
        ...nextState,
        app: {
          ...nextState.app,
          listSpl: state.app.listSpl,
        },
      };
    if (state.app.translate)
      nextState = {
        ...nextState,
        app: {
          ...nextState.app,
          translate: state.app.translate,
        },
      };
    if (state.destination)
      nextState = {
        ...nextState,
        destination: {
          ...nextState.destination,
          category: state.destination.category,
        },
      };

    return nextState;
  }
  return combinedReducer(state, action);
};
