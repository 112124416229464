/* eslint-disable @typescript-eslint/no-unused-vars */
// import { BaseXHR } from '@utils/axios';
import { call, put, takeLatest } from 'redux-saga/effects';
import { BaseXHR } from '@utils/axios';
import { GET_HOTEL, GET_LIST_HOTEL } from './slice';

export function* getHotel({ payload }: any) {
  try {
    const { SiteCode } = payload;
    const { data: hotelData } = yield call(
      BaseXHR.$get,
      `/api/banner/detail-by-key/hotel`,
      undefined,
      {
        headers: {
          SiteCode,
        },
      },
    );

    const { data: TypeData } = yield call(
      BaseXHR.$get,
      `/api/category/child/accommodation?SiteCode=${SiteCode}`,
      undefined,
      {},
    );

    yield put(
      GET_HOTEL.success({
        hotel: hotelData,
        type: TypeData,
      }),
    );
  } catch (err) {
    yield put(GET_HOTEL.failure());
  }
}
export function* getListHotel({ payload }: any) {
  try {
    const { data } = yield call(
      BaseXHR.$get,
      `/api/explore/listing?SiteCode=${payload.SiteCode}&page=1&limit=10&serviceType=accommodation`,
      undefined,
      {},
    );
    yield put(GET_LIST_HOTEL.success(data));
  } catch (err) {
    yield put(GET_LIST_HOTEL.failure());
  }
}

const hotelSagas: any = [
  takeLatest(GET_HOTEL.TRIGGER, getHotel),
  takeLatest(GET_LIST_HOTEL.TRIGGER, getListHotel),
];

export default hotelSagas;
