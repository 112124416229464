import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { createRoutine } from 'redux-saga-routines';
import { ContainerState } from './typesIndex';
import { I_Policy } from './typesIndex';

// The initial state of the App container
export const initialState: ContainerState = {
  loading: false,
  success: false,
  error: false,
  policy: null,
};

export const GET_POLICY = createRoutine('policy/getPolicy');

const policySlice = createSlice({
  name: 'policy',
  initialState,
  reducers: {
    resetStatusPage(state) {
      state.loading = initialState.loading;
      state.error = initialState.error;
      state.success = initialState.success;
    },
  },
  extraReducers: {
    [HYDRATE]: (state: ContainerState, action: PayloadAction<any>) => ({
      ...state,
      ...action.payload.policy,
    }),

    [GET_POLICY.TRIGGER]: state => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    [GET_POLICY.SUCCESS]: (state, action: PayloadAction<I_Policy>) => {
      state.policy = action.payload;
      state.loading = false;
      state.success = true;
      state.error = false;
    },
    [GET_POLICY.FAILURE]: state => {
      state.loading = false;
      state.success = false;
      state.error = true;
    },
  },
});

export const { actions, reducer, name: sliceKey } = policySlice;

export default reducer;
